import logo from '../assets/images/handv/HVco04.png';
import loginImage from '../assets/images/login/HVCarpets_LoginPage.jpeg';

import { privacyText } from './likewise/privacy';
import { termsText } from './likewise/terms';
import { cookiesText } from './likewise/cookies';
import imgCarpet from './../assets/images/Carpet.png';
import imgVinyl from './../assets/images/Vinyl.png';
import imgLaminate from './../assets/images/Laminate.png';
import imgLVT from './../assets/images/LVT.png';
import imgCarpetTiles from './../assets/images/Carpet-Tiles.png';
import imgGrass from './../assets/images/Grass.png';
import imgMatting from './../assets/images/Matting.png';
import imgContractFloors from './../assets/images/Contract.png';
import imgScreed from './../assets/images/Screed.png';
import imgProducts from '../assets/images/handvcategories/HeroBanner.png';
import imgAccount from '../assets/images/handvcategories/Account.jpeg';
import search from '../assets/svgs/searchHV.svg';
import cart from '../assets/svgs/cartHV.svg';
import bannerWithLink from '../assets/images/handvcategories/HeroBanner_with_link.jpg';
import bannerWithLinkMob from '../assets/images/handvcategories/HeroBanner_with_link_mob.jpg';

import GenericBanner from '../assets/images/handvcategories/HeroBanner.png';

import A_Accessories from '../assets/images/handvcategories/Accessories.png';
import B_Adhesives_Screeds from '../assets/images/handvcategories/AdhesiveScreeds.png';
import C_Carpet from '../assets/images/handvcategories/HV B2B Banner carpet.jpg';
import E_Contract from '../assets/images/handvcategories/ContractFlooring.png';
import G_Grass from '../assets/images/handvcategories/ArtificialGrass.png';
import H_Underlay from '../assets/images/handvcategories/Underlay.png';
import L_LVT from '../assets/images/handvcategories/LVT.png';
import N_Laminate from '../assets/images/handvcategories/HV B2B Banner laminate.jpg';
import O_Mats_Runners from '../assets/images/handvcategories/MatsRunners.png';
import Q_Matting from '../assets/images/handvcategories/Matting.png';
import R_Rugs from '../assets/images/handvcategories/Rugs.png';
import T_CarpetTile from '../assets/images/handvcategories/Category-T-Carpet Tiles.jpg';
import V_Banner_Vinyl from '../assets/images/handvcategories/HV B2B Banner vinyl.jpg';

import A_Accessories_Image from '../assets/images/categories/Cat_A_Accessories_V2.jpeg';
import B_Adhesives_Screeds_Image from '../assets/images/categories/B_Adhesive_Screeds.jpg';
import C_Carpet_Image from '../assets/images/handvcategories/HV B2B Cat carpet.jpg';
import E_Contract_Image from '../assets/images/categories/E_Contract.jpg';
import G_Grass_Image from '../assets/images/categories/G_Grass.jpeg';
import H_Underlay_Image from '../assets/images/categories/H_Underlay.jpg';
import L_LVT_Image from '../assets/images/categories/L_LVT.jpg';
import N_Laminate_Image from '../assets/images/handvcategories/HV B2B Cat laminate.jpg';
import O_Mats_Runners_Image from '../assets/images/categories/Cat_O_Mats_and_Runners_V2.jpg';
import Q_Matting_Image from '../assets/images/categories/Q_Matting.jpg';
import R_Rugs_Image from '../assets/images/categories/Cat_R_Rugs_V2.jpeg';
import T_CarpetTile_Image from '../assets/images/categories/T_Carpet_Tiles.jpg';
import V_Banner_Vinyl_Image from '../assets/images/handvcategories/HV B2B Cat vinyl.jpg';


import checked from '../assets/svgs/checked-handv.svg';
import orangeCheckmarkSvg from '../assets/svgs/orange-checkmark-handv.svg';
import orangCircleSvg from '../assets/svgs/orange-circle-handv.svg';
import Warning from '../assets/svgs/danger-sign-handv.svg';
import back from '../assets/images/HandV-Back-1.png';

import Matting_accessories from '../assets/images/categories/Matting_Dashboard_accessories_image.jpeg';
import Matting_accessories_banner from '../assets/images/category-banners/Matting_Category_banner_accessories.jpeg';

import Matting_banner_1 from '../assets/images/category-banners/Matting_Dashboard_banner_1.jpg';
import Matting_banner_2 from '../assets/images/category-banners/Matting_Dashboard_banner_2.jpg';
import Matting_banner_3 from '../assets/images/category-banners/Matting_Dashboard_banner_3.jpg';

import homeBanner1 from '../assets/images/homebanners/HandV_Banner.jpg';
import homeMob from '../assets/images/homebanners/HandV_Banner_Mobile.jpg';
import { priceListConfig } from './pricelists';
import Clearance_tile from '../assets/images/clearance/hv/HV_ClearanceSpecials_WebBanner_CategoryCover_V2.jpg';
import { Capacitor } from '@capacitor/core';
import  checkCircle  from '../assets/svgs/check-circle.svg';
import  checkCircleFilled  from '../assets/svgs/check-circle.svg';
import  checkSquare  from '../assets/svgs/check-square.svg';
import  payitBrand  from '../assets/images/Payitbrand.png';

declare let window: any;
const isRunningOnNative = Capacitor.isNativePlatform();

export const config = {
  enablePayments: false,
  site: 'handv',
  payitBrand: payitBrand, 
  checkCircle: checkCircle, 
  checkCircleFilled: checkCircleFilled, 
  checkSquare: checkSquare,
  orderAtoZ: true,
  checked: checked,
  likewiseLogo: logo,
  showAbout: false,
  companyName: 'H&V Carpets',
  copyrightText: 'H & V. All Rights Reserved.',
  privacyText: privacyText,
  termsText: termsText,
  cookiesText: cookiesText,
  imgCarpet: imgCarpet,
  imgVinyl: imgVinyl,
  imgLaminate: imgLaminate,
  imgLVT: imgLVT,
  imgCarpetTiles: imgCarpetTiles,
  imgGrass: imgGrass,
  imgMatting: imgMatting,
  imgContractFloors: imgContractFloors,
  imgScreed: imgScreed,
  imgProducts: imgProducts,
  GenericBanner: GenericBanner,
  A_Accessories: A_Accessories,
  B_Adhesives_Screeds: B_Adhesives_Screeds,
  C_Carpet: C_Carpet,
  E_Contract: E_Contract,
  G_Grass: G_Grass,
  H_Underlay: H_Underlay,
  L_LVT: L_LVT,
  N_Laminate: N_Laminate,
  O_Mats_Runners: O_Mats_Runners,
  Q_Matting: Q_Matting,
  R_Rugs: R_Rugs,
  T_CarpetTile: T_CarpetTile,
  V_Banner_Vinyl: V_Banner_Vinyl,
  A_Accessories_Image: A_Accessories_Image,
  B_Adhesives_Screeds_Image: B_Adhesives_Screeds_Image,
  C_Carpet_Image: C_Carpet_Image,
  E_Contract_Image: E_Contract_Image,
  G_Grass_Image: G_Grass_Image,
  H_Underlay_Image: H_Underlay_Image,
  L_LVT_Image: L_LVT_Image,
  N_Laminate_Image: N_Laminate_Image,
  O_Mats_Runners_Image: O_Mats_Runners_Image,
  Q_Matting_Image: Q_Matting_Image,
  R_Rugs_Image: R_Rugs_Image,
  T_CarpetTile_Image: T_CarpetTile_Image,
  V_Banner_Vinyl_Image: V_Banner_Vinyl_Image,
  A_Accessories_Matting: Matting_accessories_banner,
  A_Accessories_Image_Matting: Matting_accessories,
  Category_Specials: Clearance_tile,
  Banner_Specials: C_Carpet,
  DOCUMENT_BASE_URL: 'https://handvcarpets.com/',
  API_BASE_URL: 'https://api.handvcarpets.com/api/v1/mayne',
  API_EMAIL_BASE_URL: 'https://api.handvcarpets.com/api/v1/email',
  IMAGE_BASE_URL: (isRunningOnNative) ? 'https://uploads.likewisefloors.co.uk/uploads/' : 'https://uploads.likewisefloors.co.uk/uploads/',
  IMAGE_BASE_OLD: (isRunningOnNative) ? 'https://clever-shamir.88-208-226-64.plesk.page' : '',
  fillColour: '#C40D3C',
  orangeCheckmarkSvg: orangeCheckmarkSvg,
  orangeCircle: orangCircleSvg,
  Warning: Warning,
  back: back,
  showColourNames: true,
  gtag: 'G-HKH4R0J72N',
  suppressHeaderOnSearch: false,
  showCategoryInlineOnCheckout: true,
  showBreadcrumbOnCheckout: true,
  contactHeaderImage: imgAccount,
  showContactHeader: true,
  popupPasswordReset: false,
  showAvailableRollsInline: true,
  searchIcon: search,
  cartIcon: cart,
  cart: {
    deliveryDate: {
      title: 'Delivery Date',
      instructions: [
        "Please enter your chosen delivery date for your full order below. If you would like your order split over different delivery dates, please use the Delivery Date by Line drop down option on the left",
        "Please note orders for full rolls will be delivered within a 2-3 day window of your chosen delivery date. Our team will contact you prior to delivery to confirm the exact date. If you have any questions please let us know."
      ]
    }
  },
  pagination: {
    showFirst: false,
    showLast: false,
    prevIcon: true,
    nextIcon: true,
    even: true,
  },
  myAccount: {
    showLogin: true,
    headerImg: imgAccount
  },
  loginPage: {
    register: 'Register',
    images: [loginImage]
  },
  showHomeBanner: true,
  homeBanners: [
    {
      image: bannerWithLink,
      smallImage: bannerWithLinkMob,
      url: '/products/',
      link: '/product-cut?sku=HAV127'
    },
  ],

  newImageSolution: true,
  orderHotline: '01787 372988',
  enablePresold: true,
  enableQtyBreaks: true,
  source: 'handvweb',
  coretecCodes: [
    'JIA001', 'JIA002', 'USF001', 'USF002', 'USF003', 'USF004', 'USF005', 'USF006', 'USF007',
    'LGH001', 'LGH002'
  ],
  mattingBanner: [
    Matting_banner_1,
    Matting_banner_2,
    Matting_banner_3
  ],
  enablePricelists: true, 
  priceListConfigs: priceListConfig,
  menuLabels: {
    about: 'About',
    account: 'My Account',
    accountInfo: 'Account Information',
    accountSummary: 'Account Summary',
    cats: 'Categories',
    changePassword: 'Change Password',
    contact: 'Contact',
    home: 'Dashboard',
    logout: 'Logout',
    orders: 'My Orders',
    updateAccount: 'Update Account Info',
  },
  dashboard: {
    search: {
      labels: {
        superTitle: 'Explore Products',
      }
    },
    categories: {
      maxInList: 6,                             // -1 to show all
      labels: {
        viewAll: 'View All Categories',
      },
    },
    activities: {
      view: 'full',
      maxInList: 5,
      arrowColor: '#5d6076',
      labels: {
        title: 'Latest Activities',
        superTitle: 'My Account',           // Leave blank for none
        viewAll: 'View Orders',
      },
    },
  },
  categories: {
    header: {
      labels: {
        title: 'Product Catalogue',
      },
      image: '',
    },
    search: {
      labels: {
        superTitle: 'Explore Products',
        title: 'Choose from our wide range of products'
      }
    },
  },
  branch_name: 'H&V UK ',
  branch_address1: 'Unit 9',
  branch_address2: 'Bruntcliffe Avenue',
  branch_town: 'Morley',
  branch_pcode: 'LS27 0LL',
  branch_phone: '0121 817 2217',
  branch_email: 'sales@handvcarpets.com',
  registration_email: 'Audrey.VanCoillie@handvcarpets.com',
  postFullBasket: false,
  enableSpecials: true,
    headerBanner:[
    {
      text: 'Clearance and Specials <strong>Now Available</strong>',
      link: '/clearance'
    }
  ],
  enablePacks: false,
  showSubTitlesOnAccount: true,
  activePricelistCategories: [
    'C', 'N', 'G', 'V', 'N', 'L', 'H', 'E', 'T', 'B', 'A', 'Q', 'J'
  ],
  payit: {
    antiFraudMessage: "Anti Fraud: We will never request your account details (account number or sort code) via email",
    redirectMessage: "We are now redirecting to your bank to complete your payment",
    popupSeconds: 3,
    loadingMessage: "Loading pay by bank",
    bankRedirectionSeconds: 3,

  }
};
